import React from "react";
import { ToastContainer } from "react-toastify";
import { IToastContainerProps } from "./ts";
import "react-toastify/dist/ReactToastify.min.css";
import styles from "./styles";

const CustomToastContainer = ({ theme }: IToastContainerProps) => {
  return (
    <ToastContainer
      css={styles({ theme })}
      toastClassName="toast-notification"
      bodyClassName="toast-body"
      theme={theme}
      hideProgressBar
    />
  );
};

export default CustomToastContainer;
