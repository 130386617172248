import { css } from "@emotion/react";

export default {
  circle: css`
    pointer-events: none;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
    transition: 0.35s stroke-dashoffset;
  `,
  animated: css`
    @keyframes spin {
      100% {
        transform: rotate(270deg);
      }
    }
    animation: spin 1s linear infinite;
  `,
};
