import React from "react";
import { toast } from "react-toastify";
import { IToastProps } from "./ts";

const standardToast = ({
  position = toast.POSITION.BOTTOM_RIGHT,
  autoClose = 3500,
  closeButton = false,
  message,
  toastId,
  link,
}: IToastProps) => {
  const withLink = (
    <div>
      <div>{message}</div>
      <div>{link}</div>
    </div>
  );
  toast(link ? withLink : message, {
    position,
    autoClose,
    toastId,
    closeButton,
  });
};

const successToast = ({
  position = toast.POSITION.BOTTOM_RIGHT,
  autoClose = 3500,
  closeButton = false,
  message,
  toastId,
}: IToastProps) =>
  toast.success(message, {
    position,
    autoClose,
    toastId,
    closeButton,
  });

const infoToast = ({
  position = toast.POSITION.BOTTOM_RIGHT,
  autoClose = 3500,
  closeButton = false,
  message,
  toastId,
}: IToastProps) =>
  toast.info(message, {
    position,
    autoClose,
    toastId,
    closeButton,
  });

const errorToast = ({
  position = toast.POSITION.BOTTOM_RIGHT,
  autoClose = 3500,
  closeButton = false,
  message,
  toastId,
}: IToastProps) =>
  toast.error(message, {
    position,
    autoClose,
    toastId,
    closeButton,
  });

const warningToast = ({
  position = toast.POSITION.BOTTOM_RIGHT,
  autoClose = 3500,
  closeButton = false,
  message,
  toastId,
}: IToastProps) =>
  toast.warning(message, {
    position,
    autoClose,
    toastId,
    closeButton,
  });

export { standardToast, successToast, infoToast, errorToast, warningToast };
